import PropTypes from 'prop-types';

import { Image } from '@/design-system/atoms/Image';
import IllustrationPlayer from '@/design-system/molecules/IllustrationPlayer';

/**
 *
 * The Image Illustration component is a molecule that displays an image or an illustration.
 */
const ImageIllustration = ({ media, image, illustration }) => {
    const MediaComponent =
        media === 'image' ? (
            <Image {...image} defaultAspectRatio="16/9" mobileAspectRatio="16/9" />
        ) : (
            illustration?.scene && illustration?.title && <IllustrationPlayer {...illustration} />
        );

    return <>{MediaComponent}</>;
};

ImageIllustration.propTypes = {
    /**
     * Media type to display
     */
    media: PropTypes.oneOf(['image', 'illustration']),
    /**
     * Image properties
     */
    image: Image.propTypes,
    /**
     * Illustration properties
     */
    illustration: PropTypes.object,
};
export default ImageIllustration;
